import React from 'react';
import classNames from 'classnames';

import { OverlayProps } from './models.d';

import './Overlay.scss';

const Overlay = ({
  displayState,
  onClick,
  ariaLabel = 'click on overlay to close',
  className,
}: OverlayProps) => {
  const overlayClassNames = classNames(
    'overlay',
    {
      [`overlay--is-visible`]: displayState,
    },
    className
  );

  return (
    <button className={overlayClassNames} onClick={onClick} type="button" aria-label={ariaLabel} />
  );
};

export default Overlay;
