import { useEffect, useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import { querySignRegexp } from 'shared/regexp';

import { useQueryParamsType } from './models';

export const useQueryParams = ({ key = 'q', navigateTo = undefined }: useQueryParamsType = {}) => {
  const location = useLocation();
  const { search, pathname } = location;
  const [queryValue, setQueryValue] = useState<string | null | undefined>('' || null || undefined);
  const [parsedParams, setParsedParams] = useState<string>('');

  const params = new URLSearchParams(search);
  const handleParams = () => {
    if (location === undefined) return;

    if (queryValue === undefined) {
      setQueryValue(params.get(key));
    } else if (queryValue === '') {
      window.history.pushState(null, '', pathname);
    } else if (queryValue === null) {
      params.delete(key);
    } else {
      params.set(key, queryValue);
    }

    const paramsString = params.toString();

    const targetUrl = `${navigateTo || pathname}?${paramsString}`;

    setParsedParams(paramsString);

    if (queryValue)
      window.history.pushState({ query: queryValue }, '', targetUrl.replace(querySignRegexp, '/'));

    if (navigateTo && queryValue) navigate(targetUrl.replace(querySignRegexp, '/'));
  };

  useEffect(() => {
    handleParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, navigateTo]);

  return [queryValue, setQueryValue, parsedParams, key] as const;
};
