import React, { FC } from 'react';
import { Link } from 'gatsby';
import { beginigHashRegexp, beginigSlashRegexp, endingSlashRegexp } from 'shared/regexp';

import { NavBaseProps } from './models.d';

const NavBase: FC<NavBaseProps> = ({
  children = '',
  url,
  queryString,
  ariaLabel,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type = 'button',
  className,
  activeClassName,
  getProps,
  ...rest
}) => {
  if (url && url !== '#') {
    const isInternal: boolean = url ? beginigSlashRegexp.test(url) : false;
    const isHashtag: boolean = queryString ? beginigHashRegexp.test(queryString) : false;

    const linkProps = {
      className,
      'aria-label': ariaLabel,
      onClick,
      activeClassName,
      getProps,
    };

    if (isInternal) {
      const link = isHashtag ? `${url.replace(endingSlashRegexp, '')}${queryString}` : url;

      return (
        <Link to={link} {...linkProps} {...rest}>
          {children}
        </Link>
      );
    }

    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={className}
        aria-label={ariaLabel}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        aria-label={ariaLabel}
        // eslint-disable-next-line react/button-has-type
        {...{ type, className, onClick, onMouseEnter, onMouseLeave }}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};

export default NavBase;
