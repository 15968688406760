export const borderClasses = (variantComponent): string => {
  const borders =
    variantComponent?.border &&
    Object.keys(variantComponent.border)
      ?.map((position) => {
        if (!variantComponent.border[position]) return null;
        const { width, color, style } = variantComponent.border[position];

        return width && color && style
          ? `border-${position}-width-${width} border-${position}-color-${color} border-${position}-style-${style}`
          : '';
      })
      ?.filter((e) => e)
      .join(' ');

  const radius = variantComponent?.border?.radius;

  const borderRadius = radius ? `border-radius-${radius}` : '';

  return borders ? `${borders} ${borderRadius}` : `${borderRadius}`;
};
