export const UseCurrentLangMock = {
  useStaticQueryReturn: {
    allHomePage: {
      nodes: [
        {
          id: 'd97c2330-1bdc-5ec9-a7ba-9896c6ae779a',
          lang: 'fr',
          url: '/',
        },
      ],
    },
  },
};
