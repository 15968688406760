import React from 'react';
import loadable from '@loadable/component';

import { toPascalCase } from 'utils/stringUtils/stringUtils';

import { IconProps } from './models';

const Icon = ({ icon, className, ariaLabel, alt }: IconProps) => {
  const SvgComponent = loadable(() => import(`./icons/${toPascalCase(icon)}`));

  return (
    <>
      <SvgComponent {...{ className }} aria-hidden="true" role="img" alt={alt} />
      {ariaLabel ? <span className="sr-only">{ariaLabel}</span> : null}
    </>
  );
};

export default Icon;
