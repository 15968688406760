import React from 'react';

import Link from 'common/Link';
import Logo from 'common/Logo';
import Typography from 'common/Typography';
import SocialMedia from 'components/SocialMedia';

import { FooterProps } from './models.d';

import './Footer.scss';

const Footer = ({ footnote, link, socialMedia }: FooterProps) =>
  footnote || link || socialMedia ? (
    <footer className="footer" data-testid="footer">
      <div className="footer__wrapper">
        {link ? (
          <ul className="footer__links">
            {link?.map(({ label, url, ariaLabel }) => (
              <li className="footer__link" key={url}>
                <Link
                  color="white"
                  lineHeight="small"
                  size={18}
                  url={url || undefined}
                  ariaLabel={ariaLabel}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        ) : null}
        {socialMedia ? (
          <SocialMedia
            {...{
              variant: 'footer',
              position: 'horizontal',
              socialMedia,
              className: 'footer__socials',
            }}
          />
        ) : null}
        <div className="footer__brand">
          {footnote ? (
            <Typography
              className="footer__copyright"
              size={13}
              dangerouslySetInnerHTML={footnote}
            />
          ) : null}
          <Logo variant="secondary" className="footer__logo" />
        </div>
      </div>
    </footer>
  ) : null;

export default Footer;
