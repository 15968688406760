export enum FADEIN {
  UP = 'up',
  LISTING = 'listing',
  NONE = 'none',
}

export type FadeInAnimationType = { opacity?: number; y?: number };

export type FadeInOptionsType = {
  initial?: FadeInAnimationType;
  whileInView?: FadeInAnimationType;
  viewport?: { once?: boolean };
  transition?: { duration?: number; delay?: number };
};

export type FadeInPropsType = {
  children: React.ReactNode;
  custom?: FadeInOptionsType;
  customClass?: string;
  id?: string;
  layout?: boolean;
  animationVariant?: FADEIN;
};
