import { graphql, useStaticQuery } from 'gatsby';

import { LayoutQueryType } from 'layout/Layout/models.d';

export const useLayoutQuery = () =>
  useStaticQuery<LayoutQueryType>(graphql`
    {
      allNavigation {
        nodes {
          link {
            ...NavigationLinkFragment
          }
          socialMedia {
            icon {
              ...IconListFragment
            }
            link {
              ...LinkFragment
            }
          }
          lang
          overlayAriaLabel
        }
      }
      allFooter {
        nodes {
          footnote
          link {
            ...LinkFragment
          }
          socialMedia {
            icon {
              ...IconListFragment
            }
            link {
              ...LinkFragment
            }
          }
          lang
        }
      }
    }
  `);
