import { footerMock } from 'containers/Footer';
import { headerMock } from 'containers/Header';

export const ACTIVE_CLASS_NAVIGATION = 'mobile-navigation--active';

export const useLayoutQueryMock = {
  useStaticQueryReturn: {
    allNavigation: {
      nodes: [headerMock],
    },
    allFooter: {
      nodes: [footerMock],
    },
  },
};
