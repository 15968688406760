import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import Image from 'common/Image';
import Link from 'common/Link';

import { useCurrentLang } from 'hooks/useCurrentLang';

import { LogoProps, LogoQueryType } from './models';

const Logo = ({
  variant = 'primary',
  onClick,
  className,
  isLink = true,
  objectFit = 'cover',
}: LogoProps) => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const { allBrandSettings, allHomePage } = useStaticQuery<LogoQueryType>(graphql`
    {
      allBrandSettings {
        nodes {
          brandName
          logoPrimary {
            alt: altText
            childImageSharp {
              gatsbyImageData(width: 150, placeholder: BLURRED)
            }
          }
          logoSecondary {
            alt: altText
            childImageSharp {
              gatsbyImageData(width: 206, placeholder: BLURRED)
            }
          }
          logoTertiary {
            alt: altText
            childImageSharp {
              gatsbyImageData(width: 324, placeholder: BLURRED)
            }
          }
          ariaLabel
          lang
        }
      }
      allHomePage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const DEFAULT_HOME_PAGE_URL = '/';
  const REDIRECTS_TO_HOME_PAGE = 'redirects to home page';

  const brandSettings = allBrandSettings?.nodes?.find(({ lang }) => lang === currentLangCode);
  const homePageNode = allHomePage?.nodes?.find(({ lang }) => lang === currentLangCode);
  const logoClassNames = classNames('logo', className);

  if (!brandSettings?.logoPrimary && !brandSettings?.logoSecondary && !brandSettings?.logoTertiary)
    return null;

  const logo = {
    primary: brandSettings.logoPrimary,
    secondary: brandSettings.logoSecondary,
    tertiary: brandSettings.logoTertiary,
  };

  let link = homePageNode?.url || DEFAULT_HOME_PAGE_URL;

  if (!isLink) {
    link = '';
  }

  return (
    <Link
      url={link}
      onClick={onClick}
      className={logoClassNames}
      ariaLabel={brandSettings.ariaLabel || REDIRECTS_TO_HOME_PAGE}
    >
      <Image imageData={logo[variant]} alt={logo[variant].alt} objectFit={objectFit} />
    </Link>
  );
};

export default Logo;
