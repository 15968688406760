import { graphql, useStaticQuery } from 'gatsby';

import { DefaultBadgeUIType } from 'common/Badge';
import { DefaultButtonUIType } from 'common/Button';
import { DefaultBannerUIType } from 'components/Banner';

import { GetVariant, UISettingsQueryType, useUISettingsHook } from './models';

export const useUISettings: useUISettingsHook = (uiVariant, uiStructureAlias) => {
  const result = useStaticQuery<UISettingsQueryType>(
    graphql`
      query UISettingsQuery {
        uiSettings {
          buttonVariants {
            ...ButtonUIFragment
          }
          badgeVariants {
            ...BadgeUIFragment
          }
          bannerVariants {
            ...BannerUIFragment
          }
        }
      }
    `
  );

  if (!result?.uiSettings || !uiVariant || !uiStructureAlias) return { variant: null };

  const {
    uiSettings: { buttonVariants, badgeVariants, bannerVariants },
  } = result;

  const getVariant: GetVariant = (allComponentVariants) =>
    allComponentVariants.find(({ variant: defaultVariant }) => defaultVariant === uiVariant) ||
    null;

  const findVariant = {
    buttonUI: getVariant<DefaultButtonUIType>(buttonVariants),
    badgeUI: getVariant<DefaultBadgeUIType>(badgeVariants),
    bannerUI: getVariant<DefaultBannerUIType>(bannerVariants),
  };

  const variant = findVariant[uiStructureAlias];

  return { variant };
};
