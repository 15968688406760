import { useReducer } from 'react';

export const useSearchInput = () => {
  const initialState = '';
  const reducer = (_, action: string) => action;
  const [searchInput, setSearchInput] = useReducer(reducer, initialState);
  const [contentInput, setContentInput] = useReducer(reducer, initialState);

  return { contentInput, setContentInput, searchInput, setSearchInput };
};
