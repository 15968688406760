import { graphql, useStaticQuery } from 'gatsby';

import { useSearchQueryReturn } from './models';

export const useSearchQuery = (): useSearchQueryReturn =>
  useStaticQuery(graphql`
    {
      searchPage {
        searchPlaceholder
        url
      }
    }
  `);
