export const LEFT_SIDE_ITEMS = 2;
export const RIGHT_SIDE_ITEMS = 1;

export const subLinkTypography = {
  bold: {
    weight: 'bold',
    size: { base: 25, xxl: 30 },
    color: 'deep-koamaru',
    lineHeight: 'small',
  },
  classic: {
    weight: 'medium',
    size: { base: 25, xxl: 30 },
    color: 'deep-koamaru',
    lineHeight: 'small',
  },
  'classic-big': {
    weight: 'medium',
    size: { base: 25, xxl: 30 },
    uppercase: true,
    color: 'deep-koamaru',
    lineHeight: 'small',
  },
  image: {
    weight: 'semi-bold',
    size: { base: 25, xxl: 30 },
    color: 'white',
    lineHeight: 'small',
  },
};
