import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/Icon';

import { SearchInputContext } from 'hooks/useSearchInput/SearchInputContext';
import { useSearchQuery } from 'hooks/useSearchQuery';

import { SearchBarProps } from './models.d';

import './SearchBar.scss';

const SearchBar: FC<SearchBarProps> = ({ className, isHeader, closeMobileNavigation }) => {
  const {
    searchPage: { searchPlaceholder },
  } = useSearchQuery();

  const {
    searchInput,
    contentInput,
    setSearchInput,
    setContentInput,
    resetSearchInput,
    setSearchParam,
  } = useContext(SearchInputContext);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isHeader) {
      setSearchInput(e.target.value);
    } else {
      setContentInput(e.target.value);
    }
  };

  const handleInputValue = (): string => (isHeader ? searchInput : contentInput);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isHeader) {
      setSearchParam(searchInput);
      if (closeMobileNavigation) closeMobileNavigation();
    } else {
      setSearchParam(contentInput);
    }
    resetSearchInput();
  };

  const searchBarClasses = classNames(
    'search-bar',
    { 'search-bar--is-header': isHeader },
    className
  );

  const searchFormClasses = classNames('search-bar__form', 'background--transparent');

  return (
    <div data-testid="search-bar" className={searchBarClasses}>
      <form onSubmit={handleSubmit} className={searchFormClasses}>
        <Button
          type="submit"
          ui={{
            variant: 'simple',
            structureAlias: 'buttonUI',
          }}
          className="search-bar__button search-bar__button--submit"
          ariaLabel="submit-button"
          onClick={() => handleSubmit}
        >
          <Icon icon="search" className="search-bar__icon color--deep-koamaru" />
        </Button>
        <input
          type="text"
          onChange={handleInputChange}
          value={handleInputValue()}
          className="search-bar__input color--deep-koamaru"
          placeholder={searchPlaceholder}
        />
      </form>
    </div>
  );
};

export default SearchBar;
