import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { allClosePTags, allHtmlTagsRegexp, allNewLineTags } from 'shared/regexp';

import { ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const toTitleCase = (str: string) => startCase(camelCase(str));

export const trimString = (text: string, length: number): string => text.substring(0, length);

export const trimDotString = (text: string, length: number): string =>
  trimString(text, length).concat('...');

const BLANK_FIELD = '';
const SINGLE_SPACE = ' ';
const NON_BREAKING_SPACE = '&nbsp;';

export const trimLdsString = (text: string, length: number = 350) =>
  `${trimString(text, length)
    .replace(allNewLineTags, SINGLE_SPACE)
    .replace(allClosePTags, SINGLE_SPACE)
    .replaceAll(NON_BREAKING_SPACE, BLANK_FIELD)
    .replace(allHtmlTagsRegexp, BLANK_FIELD)}...`;

const stringUtils = {
  toPascalCase,
  trimDotString,
  toTitleCase,
};

export default stringUtils;
