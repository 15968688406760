import React from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Link from 'common/Link';

import { SocialMediaProps } from './models';

import './SocialMedia.scss';

const SocialMedia = ({
  variant,
  position = 'vertical',
  socialMedia,
  className,
}: SocialMediaProps) => {
  const socialMediaClassNames = classNames(
    'social-media',
    { [`social-media--${variant}`]: variant, [`social-media--${position}`]: position },
    className
  );

  return (
    <ul className={socialMediaClassNames}>
      {socialMedia?.map(({ icon, link: socialLink }) =>
        icon?.icon ? (
          <li key={icon.icon} className="social-media__social">
            <Link
              lineHeight="small"
              size={18}
              url={socialLink?.url}
              ariaLabel={socialLink?.ariaLabel}
              contentClassName="social__link"
            >
              <Icon icon={icon.icon} ariaLabel={icon.ariaLabel} className="social__icon" />
              {variant === 'footer' ? socialLink?.label : null}
            </Link>
          </li>
        ) : null
      )}
    </ul>
  );
};

export default SocialMedia;
