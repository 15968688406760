export const subLinkTypography = {
  bold: {
    weight: 'bold',
    size: 20,
  },
  classic: {
    weight: 'medium',
    size: 16,
  },
  'classic-big': {
    weight: 'medium',
    size: 20,
    uppercase: true,
  },
  image: {
    weight: 'semi-bold',
    size: 16,
    color: 'white',
  },
};
