import React from 'react';
import { motion } from 'framer-motion';

import { returnFadeInProps } from './utils';

import { FADEIN, FadeInOptionsType, FadeInPropsType } from './models.d';

const FadeIn = ({
  custom,
  children,
  customClass,
  animationVariant = FADEIN.UP,
  layout,
}: FadeInPropsType) => {
  if (!children) return null;

  const { initial, transition, viewport, whileInView }: FadeInOptionsType =
    returnFadeInProps(animationVariant);

  return animationVariant === FADEIN.NONE ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <motion.div
      className={customClass}
      {...{
        viewport: { ...(custom?.viewport || viewport) },
        initial: { ...(custom?.initial || initial) },
        transition: { ...(custom?.transition || transition) },
        whileInView: { ...(custom?.whileInView || whileInView) },
        layout,
      }}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
