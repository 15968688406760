import { ImageStructureMock } from 'common/Image';

import { HeaderProps } from '../models.d';

export const ACTIVE_CATEGORY = 'Découvrez Intima';

export const optionalClassNames = {
  hamburgerActive: 'hamburger--active',
  mobileNavActive: 'mobile-navigation--is-open',
};

export const headerMock: HeaderProps = {
  link: [
    {
      columns: [
        {
          location: 'left',
          link: [
            {
              label: 'Nos valeurs',
              queryString: null,
              url: null,
              variant: 'bold',
              image: null,
            },
          ],
        },
        {
          location: 'center',
          link: [
            {
              label: 'Notre engagement',
              queryString: null,
              url: null,
              variant: 'bold',
              image: null,
            },
            {
              label: 'La lutte contre l’endométriose',
              queryString: null,
              url: null,
              variant: 'classic',
              image: null,
            },
          ],
        },
        {
          location: 'right',
          link: [
            {
              label: 'Découvrez',
              queryString: null,
              url: null,
              variant: 'classic-big',
              image: null,
            },
            {
              label: 'Partenariat Endomind',
              queryString: null,
              url: '/',
              variant: 'image',
              image: ImageStructureMock,
            },
          ],
        },
      ],
      label: 'Découvrez Intima',
      queryString: null,
      url: null,
      variant: 'bold',
      image: null,
    },
    {
      columns: null,
      label: 'Mon hygiène intime',
      queryString: '',
      url: '/mon-hygiene-intime/',
      variant: 'bold',
      image: null,
    },
    {
      columns: [
        {
          location: 'left',
          link: [
            {
              label: null,
              queryString: null,
              url: '#',
              variant: 'image',
              image: ImageStructureMock,
            },
            {
              label: 'Gels intimes quotidiens',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
            {
              label: 'Crème douche',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
            {
              label: 'Lingettes',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
            {
              label: 'Cups menstruelles',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
          ],
        },
        {
          location: 'center',
          link: [
            {
              label: null,
              queryString: null,
              url: '#',
              variant: 'image',
              image: ImageStructureMock,
            },
            {
              label: 'Soins lavants intimes',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
            {
              label: 'Gel vaginal',
              queryString: null,
              url: '#',
              variant: 'classic',
              image: null,
            },
          ],
        },
        {
          location: 'right',
          link: [
            {
              label: 'Découvrez',
              queryString: null,
              url: '#',
              variant: 'classic-big',
              image: null,
            },
            {
              label: null,
              queryString: null,
              url: '#',
              variant: 'image',
              image: ImageStructureMock,
            },
          ],
        },
      ],
      label: 'NOS PRODUITS',
      queryString: null,
      url: null,
      variant: 'bold',
      image: null,
    },
  ],
  socialMedia: [
    {
      icon: {
        variant: null,
        icon: 'youtube',
        title: null,
        positionDesktop: null,
        positionMobile: null,
      },
      link: {
        label: 'Youtube',
        queryString: null,
        url: 'https://www.youtube.com/channel/UCQwcXmGi31Z17JoWhmvMBBA',
        variant: null,
        image: null,
      },
    },
    {
      icon: {
        variant: null,
        icon: 'instagram',
        title: null,
        positionDesktop: null,
        positionMobile: null,
      },
      link: {
        label: 'Instagram',
        queryString: null,
        url: 'https://www.instagram.com/intima.france/',
        variant: null,
        image: null,
      },
    },
    {
      icon: {
        variant: null,
        icon: 'facebook',
        title: null,
        positionDesktop: null,
        positionMobile: null,
      },
      link: {
        label: null,
        queryString: null,
        url: 'https://fr-fr.facebook.com/IntimaFR/',
        variant: null,
        image: null,
      },
    },
  ],
  lang: 'fr',
};
