import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Link from 'common/Link';
import Logo from 'common/Logo';
import Overlay from 'common/Overlay';
import SearchBar from 'components/SearchBar';
import SocialMedia from 'components/SocialMedia';

import DesktopSubNavigation from './DesktopSubNavigation';
import { LEFT_SIDE_ITEMS, RIGHT_SIDE_ITEMS } from './utils';

import { DesktopNavigationProps } from './models.d';

import './DesktopNavigation.scss';

const DesktopNavigation = ({
  isSubNavigationOpen,
  setIsSubNavigationOpen,
  activeCategory,
  setActiveCategory,
  link,
  socialMedia,
  className,
  overlayAriaLabel,
}: DesktopNavigationProps) => {
  const [clickable, setClickable] = useState<boolean>(true);
  const desktopSubNavigationRef = useRef<HTMLDivElement>(null!);

  const handleOverlay = useCallback(() => {
    setIsSubNavigationOpen(false);
    setActiveCategory(null);
  }, [setActiveCategory, setIsSubNavigationOpen]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleOverlay();
      }
    },
    [handleOverlay]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const logoClasses = classNames('header__logo', {
    'header__logo--hide': false,
  });

  const renderNavigation = (start?: number, end?: number) =>
    link
      ? link.slice(start, end).map((mainLink) => {
          if (!mainLink?.label) return null;

          const { label, columns, url, queryString, ariaLabel } = mainLink;

          const linkProps =
            columns && label
              ? {
                  onClick: () => {
                    if (clickable) {
                      if (isSubNavigationOpen && label === activeCategory) {
                        setIsSubNavigationOpen(false);
                        setActiveCategory(null);
                      } else {
                        setIsSubNavigationOpen(true);
                        setActiveCategory(label);
                      }
                    }
                  },

                  onMouseEnter: () => {
                    setActiveCategory(label);
                    setClickable(false);
                  },

                  onMouseLeave: (e) => {
                    if (e.relatedTarget !== desktopSubNavigationRef.current) {
                      setActiveCategory(null);
                      setClickable(true);
                    }
                  },
                }
              : {
                  url: queryString ? url + queryString : url,
                };

          return (
            <Fragment key={label}>
              <Link
                {...linkProps}
                color="deep-koamaru"
                size={{ base: 16, xxl: 18, xxxl: 20 }}
                weight="bold"
                lineHeight="small"
                className="desktop-navigation__link"
                ariaLabel={ariaLabel}
              >
                {label}
              </Link>
              <DesktopSubNavigation
                ref={desktopSubNavigationRef}
                {...{
                  columns,
                  mainLabel: label,
                  activeCategory,
                  setIsSubNavigationOpen,
                  isSubNavigationOpen,
                  setActiveCategory,
                }}
              />
            </Fragment>
          );
        })
      : null;

  return (
    <nav className={classNames('desktop-navigation', className)} data-testid="desktop-navigation">
      <Overlay
        displayState={!!activeCategory}
        onClick={handleOverlay}
        ariaLabel={overlayAriaLabel}
      />
      {renderNavigation(0, LEFT_SIDE_ITEMS)}
      <Logo className={logoClasses} />
      {renderNavigation(LEFT_SIDE_ITEMS, LEFT_SIDE_ITEMS + RIGHT_SIDE_ITEMS)}
      <SearchBar isHeader className="desktop-navigation__search" />
      {socialMedia ? <SocialMedia {...{ variant: 'header', socialMedia }} /> : null}
    </nav>
  );
};

export default DesktopNavigation;
