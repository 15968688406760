import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Image from 'common/Image';
import Link from 'common/Link';

import { subLinkTypography } from './utils';

import { DesktopSubNavigationProps } from './models.d';

const DesktopSubNavigation = forwardRef(
  (
    {
      columns,
      mainLabel,
      activeCategory,
      isSubNavigationOpen,
      setIsSubNavigationOpen,
      setActiveCategory,
    }: DesktopSubNavigationProps,
    ref: Ref<HTMLDivElement>
  ) => {
    if (!columns) return null;

    const isSubNavigationActive = mainLabel === activeCategory;
    const subNavigationClassNames = classNames('sub-navigation', {
      'sub-navigation--is-open': isSubNavigationActive,
    });

    return (
      <nav
        className={subNavigationClassNames}
        data-testid="sub-navigation"
        onMouseLeave={() => !isSubNavigationOpen && setActiveCategory(null)}
      >
        <div className="sub-navigation__wrapper" ref={mainLabel === activeCategory ? ref : null}>
          {columns.map(({ location, link: subNavigation }) => {
            const sectionClassNames = classNames('sub-navigation__section', {
              [`sub-navigation__section--${location}`]: location,
            });
            const imageClassNames = classNames('sub-navigation__image', {
              [`sub-navigation__image--${location}`]: location,
            });

            return (
              <section key={location} className={sectionClassNames}>
                {subNavigation.map(
                  ({ label, image, icon, url, queryString, variant, ariaLabel }) => {
                    if (!(label || image || icon)) return null;

                    const linkProps = url
                      ? {
                          url: queryString ? url + queryString : url,
                          onClick: () => {
                            setIsSubNavigationOpen(false);
                            setActiveCategory(null);
                          },
                        }
                      : null;

                    return (
                      <Link
                        key={label || image?.imageStructure.alt}
                        {...linkProps}
                        {...(variant ? subLinkTypography[variant] : null)}
                        ariaLabel={ariaLabel}
                      >
                        {(image || icon) && variant === 'image' ? (
                          <>
                            {image ? (
                              <Image
                                objectFit="contain"
                                {...image.imageStructure}
                                className={imageClassNames}
                              />
                            ) : null}
                            {icon ? (
                              <Icon className={imageClassNames} {...icon} alt={ariaLabel} />
                            ) : null}
                            {label ? (
                              <span className="sub-navigation__image-text">{label}</span>
                            ) : null}
                          </>
                        ) : (
                          label
                        )}
                      </Link>
                    );
                  }
                )}
              </section>
            );
          })}
        </div>
      </nav>
    );
  }
);

export default DesktopSubNavigation;
