import React, { FC } from 'react';
import classNames from 'classnames';

import NavBase from 'common/NavBase';
import Typography from 'common/Typography';

import { LinkProps } from './models.d';

import './Link.scss';

const Link: FC<LinkProps> = ({
  children,
  color,
  active,
  className,
  contentClassName,
  onClick,
  onMouseEnter,
  onMouseLeave,
  url,
  queryString,
  ariaLabel,
  lineHeight,
  weight,
  italic,
  uppercase,
  size,
  padding,
  activeClassName,
  getProps,
  animationVariant,
  align,
  dangerouslySetInnerHTML,
  as = 'span',
}) => {
  const linkClassNames = classNames(
    'link',
    {
      'link--active': active,
      'link--no-hover': url === '#' || !url,
    },
    className
  );

  const linkContentClassNames = classNames('link__typography', contentClassName);

  const navBaseProps = {
    onClick,
    url,
    queryString,
    ariaLabel,
    activeClassName,
    getProps,
    onMouseEnter,
    onMouseLeave,
  };
  const typographyProps = {
    lineHeight,
    weight,
    italic,
    size,
    padding,
    color,
    uppercase,
    align,
    animationVariant,
    dangerouslySetInnerHTML,
  };

  return (
    <NavBase className={linkClassNames} {...navBaseProps}>
      <Typography className={linkContentClassNames} {...{ ...typographyProps, as }}>
        {children}
      </Typography>
    </NavBase>
  );
};

export default Link;
