import React, { FC } from 'react';
import classNames from 'classnames';
import { useUISettings } from 'uiSettings';
import { borderClasses } from 'uiSettings/utils';

import Icon from 'common/Icon';
import Image from 'common/Image';
import NavBase from 'common/NavBase';
import Typography from 'common/Typography';

import { convertTypography } from 'utils/typography';

import { ButtonProps, DefaultButtonUIType } from './models.d';

import './Button.scss';

const Button: FC<ButtonProps> = ({
  ui,
  children,
  disabled,
  className,
  onClick,
  url,
  ariaLabel,
  activeClassName,
  type,
  image,
  icon,
  imageClassName = '',
}) => {
  const { variant: defaultButton } = useUISettings<DefaultButtonUIType>(
    ui.variant,
    ui?.structureAlias
  );

  const border = borderClasses(defaultButton);

  const buttonClasses = classNames(
    'button',
    {
      [`button--${ui?.variant}`]: ui?.variant,
      [`button--space-${ui?.space || defaultButton?.space}`]: ui?.space || defaultButton?.space,
      'button--disabled': disabled,
    },
    className
  );

  const backgroundElementClassNames = classNames(
    'button__background',
    {
      [`background--${ui?.backgroundColor || defaultButton?.backgroundColor}`]:
        ui?.backgroundColor || defaultButton?.backgroundColor,
    },
    border
  );

  const contentClassNames = classNames('button__content', {
    [`button__content--${icon?.positionMobile}`]: icon?.positionMobile,
  });

  const navBaseProps = { onClick, url, ariaLabel, type, activeClassName };

  return (
    <NavBase className={buttonClasses} {...navBaseProps} data-testid="button">
      <div className={backgroundElementClassNames} />
      {image ? (
        <Image className={imageClassName} objectFit="contain" {...image.imageStructure} />
      ) : null}
      <Typography
        as="div"
        {...(convertTypography(ui?.typography, defaultButton?.typography) || null)}
        className={contentClassNames}
      >
        {children}
        {icon?.icon ? <Icon icon={icon.icon} className="button__icon" /> : null}
      </Typography>
    </NavBase>
  );
};

export default Button;
