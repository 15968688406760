import { BREAKPOINTS } from '../constants';

import { FileNode, ImageBreakpoint, ResponsiveImage } from '../models';

const { MD, LG } = BREAKPOINTS;

export const DefaultBreakpoints: ImageBreakpoint[] = [
  { alias: 'tablet', media: `(min-width: ${MD}px) and (max-width: ${LG - 1}px)` },
  { alias: 'desktop', media: `(min-width: ${LG}px)` },
];

export const ExtendedBreakpoints: ImageBreakpoint[] = [
  { alias: 'mobile', media: '(max-width: 767px)' },
  { alias: 'tablet', media: '(min-width: 768px)' },
  { alias: 'desktop', media: '(min-width: 992px)' },
];

export const ImageExtendedClassesMock = ['image', 'test-class'];

export const ImageDefaultPropsMock = {
  alt: 'Example image alt text',
  imageData: {
    desktop: {
      childImageSharp: {
        gatsbyImageData: {
          layout: 'constrained',
          placeholder: {
            fallback:
              'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
          },
          images: {
            fallback: {
              src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
              srcSet:
                '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
              sizes: '(min-width: 575px) 575px, 100vw',
            },
            sources: [],
          },
          width: 575,
          height: 1200,
        },
      },
    },
    tablet: {
      childImageSharp: {
        gatsbyImageData: {
          layout: 'constrained',
          placeholder: {
            fallback:
              'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
          },
          images: {
            fallback: {
              src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
              srcSet:
                '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
              sizes: '(min-width: 575px) 575px, 100vw',
            },
            sources: [],
          },
          width: 575,
          height: 1200,
        },
        id: 'ef7f3481-a5a0-5b5e-89c3-12c25f00e223',
      },
    },
    mobile: {
      childImageSharp: {
        gatsbyImageData: {
          layout: 'constrained',
          placeholder: {
            fallback:
              'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
          },
          images: {
            fallback: {
              src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
              srcSet:
                '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
              sizes: '(min-width: 575px) 575px, 100vw',
            },
            sources: [],
          },
          width: 575,
          height: 1200,
        },
        id: 'ef7f3481-a5a0-5b5e-89c3-12c25f00e223',
      },
    },
  } as unknown as ResponsiveImage,
};

export const ImageExtendedPropsMock = {
  ...ImageDefaultPropsMock,
  className: 'test-class',
  breakpoints: ExtendedBreakpoints,
};

export const ImageFilePropsMock = {
  alt: 'Example Logo Alt',
  imageData: {
    childImageSharp: {
      gatsbyImageData: {
        layout: 'constrained',
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABn0lEQVQoz22T24vPURTFP+OSkHuDF5QMYn3HpBlGJE+uUwZJxstkRq7JNSTl9uLBOm/ePCpPXjx784fpnO/C7xff2nXOPnuvvdfa+wulo5kFZRyK+nO7D52XYe0bemvW/bX29QGnsLb1D9qAtb+/ayPWWorWYR3AekzRGqzVFK3HmsTaHoyRQcD7WHexrsWeY13Fuhd7gDWPdQVrDuss1hOspxSNpduRhhVa8wF4i3Ue6w7WRayF+KYpDfgD1gmsM1g3sF78C9h3OIW1iLUb62SSqgyXsY5h7QlI1fAC1sF0eBxr5R9dBwCnsT5h3aQ0wG9Ys1jXsXZhfcT6mmKzKfQF6xnWzug41GGl9SqJ77FOx/+m6VtaJ5ewbsUeYU1E+8n/UX6N9TNVX2Kdi/8d1m2KZjKsxQzpIUUT0XUqc1gyCFjX5HuErvodjn8hdGqRHxlUT7t0O7BmKI3ygIalq63Wx/HQ2JshVV2PYI1lbT5jHc0+dliHMqwaozS14jdyXeC6qKO4LfbmnLdgrUqBCrwca2viR5NTYze1H8Ba+gsJG83ikedQoAAAAABJRU5ErkJggg==',
        },
        images: {
          fallback: {
            src: '/static/4eb76d82e972462878967301d2fd651d/345a3/logo.png',
            srcSet:
              '/static/4eb76d82e972462878967301d2fd651d/6856d/logo.png 92w,\n/static/4eb76d82e972462878967301d2fd651d/52b3b/logo.png 184w,\n/static/4eb76d82e972462878967301d2fd651d/345a3/logo.png 368w',
            sizes: '(min-width: 368px) 368px, 100vw',
          },
          sources: [
            {
              srcSet:
                '/static/4eb76d82e972462878967301d2fd651d/893e3/logo.webp 92w,\n/static/4eb76d82e972462878967301d2fd651d/08386/logo.webp 184w,\n/static/4eb76d82e972462878967301d2fd651d/80ac4/logo.webp 368w',
              type: 'image/webp',
              sizes: '(min-width: 368px) 368px, 100vw',
            },
          ],
        },
        width: 368,
        height: 205,
      },
    },
  } as unknown as FileNode,
};

export const ImageStructureMock = {
  imageStructure: {
    alt: 'Image structure alt text',
    imageData: {
      desktop: {
        childImageSharp: {
          gatsbyImageData: {
            layout: 'constrained',
            placeholder: {
              fallback:
                'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
            },
            images: {
              fallback: {
                src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
                srcSet:
                  '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
                sizes: '(min-width: 575px) 575px, 100vw',
              },
              sources: [],
            },
            width: 575,
            height: 1200,
          },
        },
      },
      tablet: {
        childImageSharp: {
          gatsbyImageData: {
            layout: 'constrained',
            placeholder: {
              fallback:
                'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
            },
            images: {
              fallback: {
                src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
                srcSet:
                  '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
                sizes: '(min-width: 575px) 575px, 100vw',
              },
              sources: [],
            },
            width: 575,
            height: 1200,
          },
          id: 'ef7f3481-a5a0-5b5e-89c3-12c25f00e223',
        },
      },
      mobile: {
        childImageSharp: {
          gatsbyImageData: {
            layout: 'constrained',
            placeholder: {
              fallback:
                'data:image/webp;base64,UklGRooFAABXRUJQVlA4WAoAAAAQAAAAEwAAKQAAQUxQSF4BAAABoKtt+zlJev8Y6wzszIdiG7FtM5xsJrTtfNJJbRu71f/f//c+QVX9ug4hIiZAzZlG/6q6vo9QpsRCKwg1C1Sk7aeq2KwypdTBpq3qkZBr3Fti5NVoZe0KLaALKqarSFlCbdfMTpH6PCdGnvRSYqnZRDsyQ2WbTIM+E6Hm0yBlrUodocKm4piKFrlGB2ywqccobyp0goomKk6qkJRpcAMbwKYaoUwqtYOKVlTsUympz2uiaenI6z5SoRkEt3NgqopctwmmrQM3lGtYF04xjaHSSgIpBJZJVwgm0YHz6veOmBZ503cydprNlOUEk+zAiuMEuhk4fo/aaa55+IrYnciT/zDdND8bnQh1J2LoRGh0ovrXif8/EJ1m8+k2oTuBm5uocIqpWDUeO83EMbpJwO1M4Jo0kWjcyjgyRYWO8H+INnasQ4O9KiTdptm0vC5Jmcp1Vx5/qfB/Hx+dWpErE1ZQOCAGBAAA8BgAnQEqFAAqAAAAACWwAnTKEdzeb/jp+KvyCUj+XfZfaESs9hXLN7zfUBtgPMB+nP7Ae8B6APQA/WbrK/Ql8rP9oPgz/af0m///eHfxT6gOyL6p+mv7Q5dJ6f+NX48ahP+e/jV+UuclfJ/6n+SP5Achj7APci/nf5G+qT2wP6r7Df9E/x/4u+6x/O+VD50/wn5afQL/FP5h/Z/yo/tn/u8Kvod/qade5dZ9z/vy/KQ6ff/h9PLDucPYY/MAENBw3mIvP3GXgCG+4pnBN808AAD+//+Ij/ptGT4x5PTOpv175kymn5Emt7/lYSmR/LhIz7cVTEX9bb1jWv4FGCuWndaup2rhHhn/+b1nX7Htva5XsYfaP///LUwgLuHJ4Okgjn8ehcRfrRS2P7C4VZADh9aFsUELsFDmzJv2IW52TXZtCdMpUOpLO56U1dRjTL+JyLseR12vpPl9001XOE+HVAjuLdOQ0e27npsP2o9dip+c/6vWtTgQiwjPlS6SyQKntR82gQ/voJtq/oW+vR5tFAHMtev3FB5cbMh8e9q9PtyxzbVSHS5l/6MTqbthJxi6CO/ql/mI+sVJemI7wKYSkDlKzee/X9xFjCM3SKGPwf5DozR85IvILVjNkSM4w/4mGIncryiIILxIHUUvGjKGWFPrpCLHksqjh3jI68NiuufdWB99ukebv4uAm1s+3tlEvkyOEuBjAHX//4pDtAZs1TQfaU+BIIurtqmW0i7r/rgfijij8izuaifBjbEQ4VseZAH3QDQ94UIC61WVaOJksCHk32OlkruD76kk1CHt+4ipGLnRJydeO77WGfWFuszZYTTCjTVvr6z/sJgcsEl+Nos/8jbQyi51NdZUmdsveKt+ljPp/nFtjLenKC9o9g5mSZFIH/x7ffM96PUH2BcKWYz/ghp5swzeTvNvwc9rk11AmsAtTaD/BrwxOzTJqlBk/QtfEuO5a+AVQZ8rvV0vjNEDR/4juNHBNI8iAxXyfpk6LqxL0+rUOysxE32Mq1TBtQ31ezbiqHFlrpCTfL4TpYdqxayuDmFTv8PsFBWtTeEzvn/zDR/jqQjN8rqSEKgjXY+pqkdqyB02EtJNDup+BX5jyj+HBSRw/47SQ7xZxqcEXG7I37NhwDThQa9T00OFObRzIUNLXJ252VGBDIWXfd5ISWKR8+y8JL3g00eWfBwKgxKRL039Stkd0PyZrbXWwvmwXdfv7jEidaq+qCDw9FMgf+ogxyfz89uf4vgcPhz/C5VqQbDxVjqCxaa25W6O0pd4X85mYYKhqeqEFZh3+GwOLjwlsLJJ0YLASt3n8/Lb2hTSFP1ZBV0+RSRIkGGsSdpdiB7MM8uhJ6idzz8S6+iK0JDgAA==',
            },
            images: {
              fallback: {
                src: '/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png',
                srcSet:
                  '/static/529455cf78abbce827dc8847730bc4d2/8e133/b89377b461fc45233f8bdb036de092322757b61e.png 144w,\n/static/529455cf78abbce827dc8847730bc4d2/28c3e/b89377b461fc45233f8bdb036de092322757b61e.png 288w,\n/static/529455cf78abbce827dc8847730bc4d2/e4179/b89377b461fc45233f8bdb036de092322757b61e.png 575w',
                sizes: '(min-width: 575px) 575px, 100vw',
              },
              sources: [],
            },
            width: 575,
            height: 1200,
          },
          id: 'ef7f3481-a5a0-5b5e-89c3-12c25f00e223',
        },
      },
    } as unknown as ResponsiveImage,
  },
};

export const ChildImageSharpMock = {
  childImageSharp: {
    gatsbyImageData: {
      layout: 'constrained',
      placeholder: {
        fallback:
          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBBAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAFNVMDhAa+bqu0jnSCK/8QAHBAAAgICAwAAAAAAAAAAAAAAAgMAARAxEhM0/9oACAEBAAEFAnOLlbGTsObvDBEqtRVgtH5Z/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQMBAT8BIf/EABYRAQEBAAAAAAAAAAAAAAAAABABAv/aAAgBAgEBPwE1D//EAB0QAAEDBQEAAAAAAAAAAAAAAAEAAhASICExMnL/2gAIAQEABj8CoYV2V2USbDjUu8x//8QAIBAAAgIBAwUAAAAAAAAAAAAAAAERIVEQMUFhcZGh8f/aAAgBAQABPyFKoyxJw/KfdJyBlQT3L0o6ofWUl1xkl5ZvHrdP/9oADAMBAAIAAwAAABDr6cH/xAAWEQEBAQAAAAAAAAAAAAAAAAAQAUH/2gAIAQMBAT8QKw//xAAWEQEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8QCA7P/8QAHxABAAIBBAMBAAAAAAAAAAAAAQARITFBUXFhodHw/9oACAEBAAE/EAqGVVqrtFVgcOC/TEvqUS9+paveY0abdEU1oKApJYqaUsbOkpwYJ6bP0PE5n//Z',
      },
      images: {
        fallback: {
          src: '/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/b1d0f/57570284_2130658313649892_574446532761158656_n.jpg',
          srcSet:
            '/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/00162/57570284_2130658313649892_574446532761158656_n.jpg 180w,\n/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/b84ac/57570284_2130658313649892_574446532761158656_n.jpg 360w,\n/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/b1d0f/57570284_2130658313649892_574446532761158656_n.jpg 720w',
          sizes: '(min-width: 720px) 720px, 100vw',
        },
        sources: [
          {
            srcSet:
              '/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/2f413/57570284_2130658313649892_574446532761158656_n.webp 180w,\n/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/c390d/57570284_2130658313649892_574446532761158656_n.webp 360w,\n/static/4e1a9dea3db6ea2d58f25aeffbdcc8c4/d1a5b/57570284_2130658313649892_574446532761158656_n.webp 720w',
            type: 'image/webp',
            sizes: '(min-width: 720px) 720px, 100vw',
          },
        ],
      },
      width: 720,
      height: 900,
    },
  },
};
