import React, { createContext, useCallback, useMemo } from 'react';

import { useQueryParams } from 'hooks/useQueryParams';
import { useSearchInput } from 'hooks/useSearchInput';
import { useSearchQuery } from 'hooks/useSearchQuery';

import { SearchInputContextType } from './models';

export const SearchInputContext = createContext<SearchInputContextType>(
  {} as SearchInputContextType
);

export const SearchContextProvider = ({ children }) => {
  const { searchInput, setSearchInput, contentInput, setContentInput } = useSearchInput();

  const {
    searchPage: { url: searchLink },
  } = useSearchQuery();

  const [, setSearchParam] = useQueryParams({ navigateTo: searchLink });

  const resetSearchInput = useCallback(() => {
    setSearchInput('');
    setContentInput('');
  }, [setSearchInput, setContentInput]);

  const value = useMemo(
    () => ({
      searchInput,
      setSearchInput,
      contentInput,
      setContentInput,
      resetSearchInput,
      setSearchParam,
    }),
    [resetSearchInput, searchInput, contentInput, setContentInput, setSearchInput, setSearchParam]
  );

  return <SearchInputContext.Provider value={value}>{children}</SearchInputContext.Provider>;
};
