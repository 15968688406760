import { FooterProps } from '../models.d';

export const footerMock: FooterProps = {
  footnote: '<p>© Reckitt</p>',
  link: [
    {
      label: 'Politique de confidentialité',
      queryString: '#asdasdasd',
      url: '/privacy-policy/',
      variant: 'footer',
      image: null,
    },
    {
      label: 'Politique sur les cookies',
      queryString: null,
      url: '/cookie-policy/',
      variant: 'footer',
      image: null,
    },
    {
      label: 'Mentions légales',
      queryString: null,
      url: '/terms-and-conditions/',
      variant: 'footer',
      image: null,
    },
    {
      label: 'Nous contacter',
      queryString: null,
      url: '/contact-us/',
      variant: 'footer',
      image: null,
    },
    {
      label: 'Plan du site',
      queryString: null,
      url: '/sitemap/',
      variant: 'footer',
      image: null,
    },
  ],
  socialMedia: [
    {
      icon: {
        variant: null,
        icon: 'facebook',
        title: null,
        positionDesktop: null,
        positionMobile: null,
      },
      link: {
        label: 'Facebook',
        queryString: null,
        url: 'https://www.facebook.com/',
        variant: 'footer',
        image: null,
      },
    },
    {
      icon: {
        variant: null,
        icon: 'instagram',
        title: null,
        positionDesktop: null,
        positionMobile: null,
      },
      link: {
        label: 'Instagram',
        queryString: null,
        url: 'https://www.instagram.com/',
        variant: 'footer',
        image: null,
      },
    },
  ],
  lang: 'fr',
};
