import { LinkProps } from '../models';

export const linkDefaultClassesMock = ['link', 'test-class'];

export const LinkDefaultPropsMock: LinkProps = {
  url: '/about',
  className: 'test-class',
  size: 20,
};

export const LinkChildrenMock = 'test content';
