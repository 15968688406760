export const isBrowser = () => typeof window !== 'undefined';

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const getLocationQueryStringParam = (
  param: string,
  strategy: 'string' | 'array' = 'array'
) => {
  if (!isBrowser()) {
    return strategy === 'string' ? '' : [];
  }
  const qs = new URLSearchParams(window.location.search);

  const stringValue = decodeURIComponent(qs.get(param) || '');
  if (strategy === 'string') {
    return stringValue;
  }

  try {
    return JSON.parse(`[${stringValue}]`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    return [];
  }
};

export const generateLocationQueryString = (param: string, value: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${getLocationPath()}?${qs.toString()}`;
};

export const prepareClassName = (name: string) => String(name).replace(/\s/g, '-').toLowerCase();

export const getWindowWidth = () => window && window.innerWidth;
export const getQueryString = (qs: string = 'q'): string | null =>
  new URLSearchParams(window.location.search.toString()).get(qs);
