import React from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Image from 'common/Image';
import Link from 'common/Link';

import { subLinkTypography } from './utils';

import { MobileSubNavigationProps } from './models.d';

import './MobileNavigation.scss';

const MobileSubNavigation = ({
  columns,
  mainLabel,
  activeCategory,
  setIsOpen,
}: MobileSubNavigationProps) =>
  columns && mainLabel === activeCategory ? (
    <section className="sub-navigation" data-testid="sub-navigation">
      {columns.map(({ location, link: subNavigation }, idx) => {
        if (!location) return null;

        const sectionClassNames = classNames('sub-navigation__section', {
          [`sub-navigation__section--${location}`]: location,
        });

        return (
          <section key={location} className={sectionClassNames}>
            {subNavigation.map(({ label, url, queryString, image, icon, variant, ariaLabel }) => {
              if (!(label || image || icon)) return null;

              const subLinkProps = url
                ? {
                    url: queryString ? url + queryString : url,
                    onClick: () => setIsOpen(false),
                  }
                : null;

              return (
                <Link
                  key={label || image?.imageStructure.alt}
                  {...subLinkProps}
                  color="deep-koamaru"
                  {...(variant ? subLinkTypography[variant] : null)}
                  lineHeight="small"
                >
                  {(image || icon) && variant === 'image' ? (
                    <>
                      {image ? (
                        <Image
                          objectFit="contain"
                          {...image?.imageStructure}
                          className={classNames(
                            'sub-navigation__image',
                            `sub-navigation__image--${idx}`
                          )}
                        />
                      ) : null}
                      {icon ? (
                        <Icon
                          {...icon}
                          className={classNames(`sub-navigation__image--${idx}`)}
                          alt={ariaLabel}
                        />
                      ) : null}
                      <span className="sub-navigation__image-text">{label}</span>
                    </>
                  ) : (
                    label
                  )}
                </Link>
              );
            })}
          </section>
        );
      })}
    </section>
  ) : null;

export default MobileSubNavigation;
