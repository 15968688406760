import React from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Link from 'common/Link';
import SearchBar from 'components/SearchBar';
import SocialMedia from 'components/SocialMedia';

import MobileSubNavigation from './MobileSubNavigation';

import { MobileNavigationProps } from './models.d';

import './MobileNavigation.scss';

const MobileNavigation = ({
  isOpen,
  setIsOpen,
  isSubNavigationOpen,
  setIsSubNavigationOpen,
  activeCategory,
  setActiveCategory,
  link,
  socialMedia,
  className,
}: MobileNavigationProps) => {
  const navigationClassNames = classNames(
    'mobile-navigation',
    {
      'mobile-navigation--is-open': isOpen,
    },
    className
  );

  const closeMobileNavigation = () => {
    setIsOpen(false);
    setIsSubNavigationOpen(false);
    setActiveCategory(null);
  };

  return (
    <nav className={navigationClassNames} data-testid="mobile-navigation">
      {link?.map(({ label, columns, url, queryString, ariaLabel }) => {
        if (!label) return null;

        const linkProps = columns
          ? {
              onClick: () => {
                setIsSubNavigationOpen(true);
                setActiveCategory(label);
              },
            }
          : { url: queryString ? url + queryString : url, onClick: closeMobileNavigation };

        return !isSubNavigationOpen ? (
          <Link
            key={label}
            {...linkProps}
            color="deep-koamaru"
            size={20}
            weight="bold"
            lineHeight="small"
            className="mobile-navigation__link"
            ariaLabel={ariaLabel}
          >
            {label}
            <Icon icon="arrow-right" className="link__icon" />
          </Link>
        ) : (
          <MobileSubNavigation {...{ columns, mainLabel: label, activeCategory, setIsOpen }} />
        );
      })}
      {!isSubNavigationOpen ? (
        <>
          {socialMedia ? (
            <SocialMedia
              {...{ variant: 'header', socialMedia, className: 'mobile-navigation__socials' }}
            />
          ) : null}
          <SearchBar isHeader {...{ closeMobileNavigation }} />
        </>
      ) : null}
    </nav>
  );
};

export default MobileNavigation;
