import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Hamburger from 'common/Hamburger';
import Logo from 'common/Logo';
import DesktopNavigation from 'components/DesktopNavigation';
import MobileNavigation from 'components/MobileNavigation';

import { isBrowser } from 'utils/browser';
import { useScreenService } from 'hooks/useScreenService';

import { HeaderProps } from './models.d';

import './Header.scss';

const Header = ({ link, socialMedia, overlayAriaLabel }: HeaderProps) => {
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [isSubNavigationOpen, setIsSubNavigationOpen] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const { isMobile, isXxl } = useScreenService();

  const logoClasses = classNames('header__logo', 'header__logo--mobile', 'hide--on-desktop', {
    'header__logo--hide': isMobileOpen,
  });

  useEffect(() => {
    if (isBrowser()) {
      const bodyElement = window.document.body;

      if (isMobileOpen && !isXxl) {
        bodyElement.classList.add('scroll--prevent');
      } else {
        bodyElement.classList.remove('scroll--prevent');
      }
    }
  }, [isMobileOpen, isXxl]);

  const hideSubNavigation = () => {
    setIsSubNavigationOpen(false);
    setActiveCategory(null);
  };

  const toggleMobileNavigation = () => {
    setIsMobileOpen((prevState) => !prevState);
  };

  return (
    <header className="header" data-testid="header">
      <div className="header__wrapper">
        <Hamburger
          isOpen={isMobileOpen}
          onClick={() => {
            hideSubNavigation();
            toggleMobileNavigation();
          }}
          className="hide--on-desktop"
        />
        <Logo className={logoClasses} onClick={hideSubNavigation} />
        {isMobile ? (
          <MobileNavigation
            {...{
              link,
              socialMedia,
              isSubNavigationOpen,
              setIsSubNavigationOpen,
              activeCategory,
              setActiveCategory,
            }}
            isOpen={isMobileOpen}
            setIsOpen={setIsMobileOpen}
            className="hide--on-desktop"
          />
        ) : null}
        {!isMobile ? (
          <DesktopNavigation
            {...{
              link,
              socialMedia,
              isSubNavigationOpen,
              setIsSubNavigationOpen,
              activeCategory,
              setActiveCategory,
              overlayAriaLabel,
            }}
            className="hide--on-mobile"
          />
        ) : null}
      </div>
    </header>
  );
};

export default Header;
