import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';
import Footer from 'containers/Footer';
import Header from 'containers/Header';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useLayoutQuery } from 'hooks/useLayoutQuery';
import { SearchContextProvider } from 'hooks/useSearchInput/SearchInputContext';

import 'styles/main.scss';
import './Layout.scss';

import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Loadable from 'components/Loadable';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, background = 'white', urls, seo, className }) => {
  const { currentLang } = useCurrentLang();
  const { allFooter, allNavigation } = useLayoutQuery();
  const [currentLangCode] = currentLang;
  const footer = allFooter?.nodes?.find(({ lang }) => lang === currentLangCode);
  const header = allNavigation?.nodes?.find(({ lang }) => lang === currentLangCode);

  const layoutClassNames = classNames(
    'layout',
    { [`background--${background}`]: background },
    className
  );

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const {
    siteSettings: { skipToMainContentLabel },
  } = useStaticQuery<{
    siteSettings: {
      skipToMainContentLabel: string;
    };
  }>(graphql`
    {
      siteSettings {
        skipToMainContentLabel
      }
    }
  `);

  return (
    <div className={layoutClassNames}>
      <a href="#main" className="skip-to-main-content-link">
        {skipToMainContentLabel}
      </a>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <SearchContextProvider>
        {seo && urls ? (
          <>
            <Seo {...{ urls, seo }} />
            <PageSchema
              type="WebPage"
              name={seo.title}
              data={{
                metaTitle: seo.title,
                metaDescription: seo.description,
                metaKeywords: seo.keywords,
              }}
            />
          </>
        ) : null}
        <Header {...header} />
        <main id="main" className="layout__main">
          {children}
        </main>
      </SearchContextProvider>
      <Footer {...footer} />
    </div>
  );
};

export default Layout;
